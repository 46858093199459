import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  userInfo:{},
	  vipInfo:{},
	  isLogin:false
  },
  mutations: {
	  //保存用户登录信息
	  saveUserInfo(state,userInfo){
		  state.userInfo = userInfo;
	  },
	  //保存用户vip信息
	  saveVipInfo(state,vipInfo){
	  	  state.vipInfo = vipInfo;
	  },
	  //保存用户登录状态
	  saveIsLogin(state,isLogin){
	  	  state.isLogin = isLogin;
	  },
  },
  actions: {
  },
  modules: {
  }
})
